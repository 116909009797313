import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, SiteMain  } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

import algoliasearch from 'algoliasearch';
import PostPreview from '../components/search/post-preview';
import { InstantSearch, SearchBox, InfiniteHits, Highlight, Configure } from 'react-instantsearch';
import SearchIcon from '../components/icons/search';
import {TinyButton as ScrollUpButton} from 'react-scroll-up-button';
import Burger from '../components/BurgerMenu';
import { Link } from "gatsby";
import { useEffect, useState } from 'react';

// import 'instantsearch.css/themes/algolia.css';
import 'instantsearch.css/themes/reset.css';

const searchPost = css`
  display: flex;
  gap: 30px;
  @media (max-width: 696px){
    flex-direction: column;
  }
`;

const PostLink = css`
  box-shadow: none; 
  background-image: none;
`;

const PostImage = css`
  border-radius: 8px; 
  width: 40% !important;
`;

function Hit({ hit }) {
  return (
    <Link css={PostLink} to={hit.fields.slug}>
      <div css={searchPost}>
        <img css={PostImage} src={ hit.frontmatter.image.childImageSharp.fixed.src }/>
        <div>
          <header>
            <h3>
              <Highlight hit={hit} attribute="frontmatter.title" tagName="mark" />
            </h3>
            <small>{(hit.frontmatter.date)}</small>
          </header>
          <section>
            <p>
              <Highlight hit={hit} attribute="excerpt" tagName="mark" />
            </p>
          </section>
        </div>
      </div>
    </Link>
  );
}

const searchClient = algoliasearch('DXOJAI9LZU', 'be158981e442b1f5b42269822051dd47');

const PageTemplate = css`
  .text-center {
    text-align: center;
  }
  small {
    text-transform: uppercase;
    color: #999999;
  }
  .ais-InfiniteHits-item {
    padding-left: 0;
  }
  .ais-InfiniteHits-item:not(:last-child) {
    border-bottom: 1px solid var(--borderColor);
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
  .ais-Highlight-highlighted {
    background-color: #c7f3c4;
  }
  .ais-InfiniteHits {
    margin-top: 30px;
  }
  .ais-SearchBox-form {
    text-align: center;
  }
  .ais-SearchBox-input {
    border: 1px solid var(--textLink);
    padding: 10px 24px;
    margin-right: 5px;
    text-align: left;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    background: transparent;
    border-radius: 200px;
    -webkit-transition: 0.07s;
    transition: 0.07s;
    width: 100%;
    box-sizing: border-box;
  }
  .ais-SearchBox-input::placeholder {
    color: var(--textLink);
  }
  .ais-SearchBox-submit, .ais-SearchBox-reset {
    display: none;
  }
  .ais-InfiniteHits-loadMore {
    margin-top: 1.8rem;
  }
  .ais-InfiniteHits-loadMore.ais-InfiniteHits-loadMore--disabled {
    display: none;
  }
`;

const SearchBox_icon = css`
  position: absolute;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: currentColor;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  right: 1.8rem;
  top: 0;
  width: 2rem;
  height: 4.8rem;
`;

const SearchPage_Header = css`
  background: linear-gradient(135deg, rgba(95,45,238,0.69) 0%, rgba(35,244,103,0.2) 20%, rgba(22,232,193,0.2) 40%, rgba(15,226,232,0.2) 60%, rgba(10,221,240,0.2) 80%, rgba(0,144,255,0.69) 100%);
  background-color: #041e33;
  margin: 0;
  width: 100%; 
  max-width: none;
`;

const SearchPage_Header_Description = css`
  margin-bottom: 0;
  color: white;
  font-size: 18px;
`;

const MainContent = css`
  backgroundColor: var(--postBg);
  color: var(--textNormal);
  transition: background-color 0.3s ease;
`;

const FullContent = css`
  color: var(--textNormal);
  transition: background-color 0.3s ease;
  font-family: Rubik;
  margin-bottom: 30px;
  max-width: 1320px;
`;

const Search: React.FC = () => {

  const [searchState, setSearchState] = useState<any>();

  useEffect(() => {
    // Function to get the search query parameter
    const getSearchQueryParam = (): string | null => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get('search');
    };

    // Set initial search state from the URL parameter
    const initialQuery = getSearchQueryParam();
    if (initialQuery) {
      setSearchState(initialQuery);
    }
  }, []);


  return(
    <IndexLayout>
      <Burger/>
      <ScrollUpButton style={{
        fill: 'var(--textLink)',
        background: 'none',
        left: '20px'
      }}/>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <SiteNav />
        </header>
        <PostFullHeader css={SearchPage_Header}>
          <img src="/static/ef9de6fb8303de0a4dfd35b593c46aa9/9f2d5/ttt-blog_banner.png"/>
          <p css={SearchPage_Header_Description}>
            Search below to find exclusive insights related to Tech, Microsoft, Cloud Services, and more.
          </p>
        </PostFullHeader>
        <main className="site-main" css={[SiteMain, outer, MainContent]}>
          <article className="post page" css={[PostFull, NoImage]} style={{ marginTop: '75px'}}>
            <PostFullContent className="post-full-content" css={FullContent}>
              <InstantSearch 
                searchClient={searchClient} 
                indexName="gatsby-blog-ttt" 
                show-more="true"
              >
                <div style={{position: "relative"}}>
                  <SearchBox
                    placeholder="Search for a post..."
                  />
                  <div css={SearchBox_icon}>
                    <SearchIcon />
                  </div>
                </div>
                <InfiniteHits showPrevious={ false } hitComponent={ Hit } />
                <Configure query={ searchState } />
              </InstantSearch>
            </PostFullContent>
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  )
};

export default Search;
